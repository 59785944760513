import React from 'react';
import './officers.scss';
import Brother from './brother';
import { brothers } from './FilteredBros';

const BrotherGroupContainer = ({ brothers, title, email }) => (
  <div className="flex justify-center flex-col items-center">
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {brothers.map(bro => (
        <Brother brother={bro} isFlat />
      ))}
    </div>
    <h3>{title}</h3>
    <a href={email}>{email}</a>
  </div>
);

const Officers = () => {
  const socialChairs = brothers
    .filter(bro => bro.active && bro.role.includes('Social Chair'))
    .map(bro => ({ ...bro }));
  const socialChairEmail = 'sn-social@mit.edu';
  const rushChairs = brothers
    .filter(bro => bro.active && bro.role.includes('Rush Chair'))
    .map(bro => ({ ...bro }));
  const rushChairEmail = 'sn-rush@mit.edu';
  const commander = brothers
    .filter(bro => bro.active && bro.role.includes('Eminent Commander'))
    .map(bro => ({ ...bro }));
  const commanderEmail = 'sn-commander@mit.edu';
  return (
    <div className="flex flex-col lg:justify-between p-5 justify-center items-center lg:flex-row gap-5">
      <BrotherGroupContainer
        brothers={socialChairs}
        title="Social Chairs"
        email={socialChairEmail}
      />
      <BrotherGroupContainer
        brothers={commander}
        title="Commander"
        email={commanderEmail}
      />
      <BrotherGroupContainer
        brothers={rushChairs}
        title="Rush Chairs"
        email={rushChairEmail}
      />
    </div>
  );
};

export default Officers;
